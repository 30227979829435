import React from 'react';
import moment from 'moment';

export const api = {
    space: 'of',
    headers: {},
    headersFile: {},

    key_of: 'Z58CuXEq1fQFzZ27qPJNJhIwRyu3qrkeJMegXVNtKYvb1jz5TQxHC7yet3WZ92GJ',
    key_qa: 'eB72eUVWMbLsll4xt7fLfnpe9zUTFXBbKQSb564fT42CQ2wGeNSZZ31ns62N6djT',
    key_on: 'eB72eUVWMbLsll4xt7fLfnpe9zUTFXBbKQSb564fT42CQ2wGeNSZZ31ns62N6djT',

    server_of: 'http://localhost/upnmg/api/v1/',
    server_on: 'https://api.theblowapp.com/upnmg/',
    server_qa: 'https://qa-api.upnmg.com/v1/',

    platform_of: 'localhost',
    platform_qa: 'qa-admin',
    platform_on: 'admin',
}

export const app = {
    version: '1.0.0',
    dbpref: 'upnmg_'
}

export const initialize = () => {
    if (window.location.host.match(/localhost/i)) {
        api.space = 'of';
    } else if (window.location.host === 'qa-admin.upnmg.com') {
        api.space = 'qa';
    } else {
        api.space = 'on';
    }
    api.apiURL = api[`server_${api.space}`];
    api.apiKey = api[`key_${api.space}`];
    api.apiPlatform = api[`platform_${api.space}`];
}

export const dates = {
    yr: moment().format('YYYY'),
}


// Storage
export const setStorage = (key, value) => {
    if (key && value) {
        localStorage.setItem(app.dbpref + key, value);
    }
}
export const getStorage = (key) => {
    const value = localStorage.getItem(app.dbpref + key);
    return value || '';
}
export const setStorageJson = (key, value) => {
    if (key && value) {
        localStorage.setItem(app.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key) => {
    if (key) {
        const value = localStorage.getItem(app.dbpref + key);
        return JSON.parse(value) || [];
    }
}
export const delStorage = (key) => {
    if (key) {
        localStorage.removeItem(app.dbpref + key);
    }
}


export const inArray = (needle, haystack) => {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
    }
    return false;
}
export const mergeObj = (obj, src) => {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
export const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
}


// Data Request
initialize();
export const jsnData = (str) => {
    if (typeof str !== 'object') {
        var obj = {};
        var data = str.split('&');
        for (var key in data) {
            obj[data[key].split('=')[0]] = data[key].split('=')[1];
        }
        return obj;
    }
    return str;
}
export const serData = (obj) => {
    var str = [];
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
}

// Spinners
export const fspinner = <div style={{ textAlign: 'center', color: '#999', lineHeight: 320 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-5x"></i></div>;
export const fspinner_sm = <div style={{ textAlign: 'center', color: '#999', lineHeight: 120 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-3x"></i></div>;
export const fspinner_xs = <i className="fa fa-spin fa-circle-o-notch"></i>;

export const redirect = (to) => {
    window.location = to;
}

export const generateOptions = (length, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const hasR = (role) => {
    let user = getStorageJson('user');
    let myRoles = ((user.access || {}).data || '').split(',');
    var hasR = !role || (myRoles.includes(role) || myRoles.includes('*'));
    return hasR;
};

export const numberFormat = (number, decimal = 0) => {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: decimal }).format(number);
    // return new Intl.NumberFormat('en-US', { decimal, style: 'currency', currency: '', }).format(number);
}