import { func } from '../../providers';
import { SET_SET_SETTINGS } from '../_types';

const initialState = {
    settings: func.getStorageJson('settings'),

    navigation: [
        { code: '', name: 'Dashboard', icon: 'home', link: '', subs: [] },
        {
            code: 'mmb', name: 'Members', icon: 'star', link: 'members',
            subs: [
                {
                    code: 'mmb', name: 'Members list', link: 'members', rules: [
                        { code: 'mmb_upd', name: 'Edit member', },
                    ]
                },
                { code: 'mmb_add', name: 'Member registration', link: 'members/form', rules: [], },
                { code: 'mmb_que', name: 'Member query', link: 'members/query', rules: [], },
            ]
        },
        {
            code: 'rep', name: 'Reports', icon: 'book', link: 'reports',
            subs: [
                { code: 'rep_due', name: 'Due\'s Report', link: 'reports/dues', rules: [], },
                { code: 'rep_trm', name: 'Termination Report', link: 'reports/termination', rules: [], },
                { code: 'rep_ret', name: 'Retirement Report', link: 'reports/retirement', rules: [], },
                { code: 'rep_fnd', name: 'Funds Report', link: 'reports/funds', rules: [], },
                { code: 'rep_hgh', name: 'Hire-Purchase Report', link: 'reports/hire-purchase', rules: [], },
            ]
        },
        {
            code: 'usr', name: 'Users', icon: 'users', link: 'users',
            subs: [
                {
                    code: 'usr', name: 'Manage users', link: 'users', rules: [
                        { code: 'usr_upd', name: 'Edit user', },
                    ],
                },
                { code: 'usr_add', name: 'Create user', link: 'users/form', rules: [], },
                { code: 'usr_acc', name: 'Users access', link: 'users/access', rules: [], },
            ]
        },
    ]
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SET_SET_SETTINGS:
            return {
                ...state,
                [action.key]: action.value,
            };
    }
};


export default dataReducer;